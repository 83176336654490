
import { defineComponent } from "vue";
import { IWebOutageModel } from "../models/IWebOutage.model";
import axios from "axios";
import dayjs from "dayjs";

export default defineComponent({
  name: "Web",
  data() {
    return {
      upcommingOutages: null,
      historyOutages: null,
    };
  },
  mounted() {
    axios.get("outages-web.json").then((res) => {
      const todayDate = +new Date();
      if (res) {
        let upcomming = res.data.filter((a: IWebOutageModel) => {
          if (+new Date(a.dateTo) > todayDate) {
            return a;
          }
        });
        this.upcommingOutages = upcomming;

        let history = res.data.filter((a: IWebOutageModel) => {
          if (todayDate > +new Date(a.dateTo)) {
            return a;
          }
        });

        this.historyOutages = history;
      }
    });
  },
  methods: {
    formatDate(dateString: string, type: string) {
      const date = dayjs(dateString);
      return type == "year" ? date.format("YYYY") : date.format("DD.MM.YYYY, HH:mm");
    },
  },
});
