
import { defineComponent } from "vue";
import { IWebOutageModel } from "../models/IWebOutage.model";
import axios from "axios";

export default defineComponent({
  name: "Home",
  data() {
    return {
      webOutages: null,
      electricityOutages: 0,
      waterOutages: 0,
    };
  },
  components: {},
  mounted() {
    //CEZ - https://api.bezstavy.cz/cezd/api/inspecttown/578134

    axios.get("outages-web.json").then((res) => {
      const todayDate = +new Date();
      if (res) {
        let upcomming = res.data.filter((a: IWebOutageModel) => {
          if (+new Date(a.dateTo) > todayDate) {
            return a;
          }
        });
        this.webOutages = upcomming.length;
      }
    });
  },
});
